/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { Con, TextCon, HeaderText, Subtext, RedDot } from "./styled/home";
import { IndexIcon } from "../Assets/Images/IndexIcon";
import { CircleCheck } from "../Assets/Images/CircleCheck";
import { ButtonComponent } from "../Component/ButtonComponent";
import { ModalComponent } from "../Component/Modal";
import { IndexIconEn } from "../Assets/Images/IndexIconEn";
import Header from "../Component/Header/Header";
import Logo from "../Assets/Images/Logo";

export const Exibition = () => {
  const { i18n, t } = useTranslation();
  const [show, setshow] = React.useState(false);

  const [lang, setlang] = React.useState(i18n.language);
  const [loading, setshowLang] = React.useState(false);

  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    i18n.changeLanguage(lang);
  }, []);

  const arabic = lang === "ar";

  const changeLanguageHandler = (lang) => {
    setlang(lang);
    i18n.changeLanguage(lang);
    setshowLang(false);
  };

  return (
    <>
      {/* <Header
        onBoarding={
          <ButtonComponent
            style={{ width: 100, zIndex: 999 }}
            width="100%"
            langBtn={true}
            label={lang === "ar" ? "En" : "Ar"}
            loading={false}
            btnType={"dark"}
            onClick={() => {
              changeLanguageHandler(lang === "ar" ? "en" : "ar");
            }}
          />
        }
      /> */}
      <Con>
        {!arabic ? (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              right: 0,
              bottom: "-34px",
              // zIndex: 99,
            }}
          >
            <IndexIconEn />
          </div>
        ) : (
          <div
            className="pc-view"
            style={{
              position: "absolute",
              left: 0,
              bottom: "-34px",
            }}
          >
            <IndexIcon />
          </div>
        )}

        <div
          style={{ zIndex: 99, position: "relative" }}
          className="mobile-view"
        >
          <div
            style={{
              width: "100%",
              zIndex: 99,
              padding: 15,
              position: "absolute",
              margin: "auto",
            }}
          >
            <Logo width={"145px"} height={"36.75px"} />
          </div>
          <img
            style={{
              position: "relative",
              //   bottom: 20,
              width: "80%",
              zIndex: 9,
            }}
            src={
              arabic
                ? "https://storage.googleapis.com/uapply/Subject-removebg.png"
                : "https://storage.googleapis.com/uapply/Subject-removebg.png"
            }
            alt="img"
          />
          <img
            alt="img"
            src="https://storage.googleapis.com/uapply/flag-bg.png"
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              height: "100%",
              left: 0,
            }}
          />
        </div>

        <TextCon
          direction={arabic ? "flex-end" : "flex-start"}
          className="container"
        >
          <HeaderText arabic={arabic}>
            أكبر معرض للجامعات البريطانية في الكويت 2025
          </HeaderText>
          {/* <HeaderText arabic={arabic}>{t("common:homeHeader2")}</HeaderText> */}

          <div style={{ marginTop: 20 }}>
            <Subtext fontWeight={900} arabic={arabic}>
              لغة <RedDot /> بكلوريوس <RedDot /> ماجستير <RedDot /> دكتوراة
            </Subtext>
          </div>

          <div className="mobile-view" style={{ width: "90%" }}>
            <ButtonComponent
              style={{
                marginTop: 20,
                padding: "0 58px",
                width: "90%",
                zIndex: 99,
                marginBottom: 20,
                borderRadius: 8,
              }}
              width="100%"
              className="res-home-btn "
              label={"ابدأ بالتسجيل"}
              loading={false}
              btnType={"dark"}
              onClick={() => setshow(true)}
            />
          </div>
        </TextCon>
        <div
          className="mobile-view"
          style={{ zIndex: 99, position: "relative" }}
        >
          <Subtext
            fontWeight={900}
            arabic={arabic}
            style={{ marginBottom: 50, padding: "0 20px" }}
          >
            تاريخ 3 و 4 فبراير من الساعة 4م إلى ٩م في فندق راديسون بلو البدع،
            قاعة دسمان
          </Subtext>
          {/* <IndexIconMobile /> */}
          <img
            style={{
              position: "relative",
              bottom: 30,
              width: "80%",
              zIndex: 99,
            }}
            src={
              arabic
                ? "https://storage.googleapis.com/uapply/ipad_hq.png"
                : "https://storage.googleapis.com/uapply/ipad_hq.png"
            }
            alt="img"
          />
          <div>
            <img
              alt="img"
              src="https://storage.googleapis.com/uapply/blue-bg.png"
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            />
          </div>
        </div>
        {show ? (
          <ModalComponent show={show} onHide={() => setshow(!show)} />
        ) : null}
      </Con>
    </>
  );
};
